import * as echarts from 'echarts/lib/echarts'

export const typesOption = {
  legend: {
    top: 'center',
    left: 'right',
    orient: 'vertical',
    itemHeight: 6,
    itemWidth: 6,
    itemGap: 5,
    padding: [5, 5],
    formatter: ['{a|{name}}'].join('\n'),
    textStyle: {
      rich: {
        a: {
          fontSize: 9,
          lineHeight: 1,
          verticalAlign: 'middle'
        }
      }
    }
  },
  // legend: [
  //   {
  //     top: '40px',
  //     left: 'right',
  //     data: ['工装监测 110', '工装监测 11']
  //   },
  //   {
  //     top: '60px',
  //     left: 'right',
  //     data: ['工装监测 10', '明火监测 110']
  //   },
  //   {
  //     top: '80px',
  //     left: 'right',
  //     data: ['明火监测 130']
  //   }
  // ],
  // color: [],
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['50%', '70%'],
      center: ['35%', '50%'],
      label: {
        formatter: '{d}%'
      },
      labelLine: {
        show: true,
        length: 6
      },
      data: [
        { value: 1048, name: '工装监测 110' },
        { value: 735, name: '工装监测 11' },
        { value: 580, name: '工装监测 10' },
        { value: 484, name: '明火监测 110' },
        { value: 300, name: '明火监测 130' }
      ]
    }
  ]
}

export const trendOption = {
  // color: ['#747EF7', '#FBB200', '#35DEB3', '#F20404', '#01B4D2'],
  // color: [],
  legend: {
    data: ['工装监测', '手机监测', '口罩监测', '明火监测', '网帽监测'],
    bottom: 0,
    itemHeight: 6,
    itemWidth: 6,
    itemGap: 5,
    padding: [1, 5],
    formatter: ['{a|{name}}'].join('\n'),
    textStyle: {
      rich: {
        a: {
          fontSize: 10,
          lineHeight: 10,
          verticalAlign: '8'
        }
      }
    }
  },
  grid: {
    left: '3%',
    right: '6%',
    bottom: '16%',
    top: '5%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: ['01', '02', '03', '04', '05', '06', '07'],
      axisTick: false,
      axisLine: {
        show: false
      },
      axisLabel: {
        show: true,
        fontSize: 11
      }
      // name: '月'
    }
  ],
  yAxis: [
    {
      type: 'value',
      scale: true,
      minInterval: 1,
      axisLabel: {
        show: true,
        fontSize: 11
      }
    }
  ],
  series: [
    {
      name: '工装监测',
      type: 'line',
      stack: 'a',
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.5,
            color: 'rgba(116,126,247,0.9)'
          },
          {
            offset: 1,
            color: 'rgba(116,126,247,0.3)'
          }
        ])
      },
      emphasis: {
        focus: 'series'
      },
      data: [40, 32, 10, 64, 90, 40, 50]
    },
    {
      name: '手机监测',
      type: 'line',
      stack: 'a',
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.5,
            color: 'rgba(251,178,0,0.9)'
          },
          {
            offset: 1,
            color: 'rgba(251,178,0,0.3)'
          }
        ])
      },
      emphasis: {
        focus: 'series'
      },
      data: [20, 82, 11, 34, 20, 40, 10]
    },
    {
      name: '口罩监测',
      type: 'line',
      stack: 'a',
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.5,
            color: 'rgba(53,222,179,0.9)'
          },
          {
            offset: 1,
            color: 'rgba(53,222,179,0.3)'
          }
        ])
      },
      emphasis: {
        focus: 'series'
      },
      data: [20, 32, 21, 34, 90, 30, 20]
    },
    {
      name: '明火监测',
      type: 'line',
      stack: 'a',
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.5,
            color: 'rgba(242,4,4,0.9)'
          },
          {
            offset: 1,
            color: 'rgba(242,4,4,0.3)'
          }
        ])
      },
      emphasis: {
        focus: 'series'
      },
      data: [20, 42, 31, 34, 90, 30, 20]
    },
    {
      name: '网帽监测',
      type: 'line',
      stack: 'a',
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      label: {
        show: true,
        position: 'top'
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.5,
            color: 'rgba(1,180,210,0.9)'
          },
          {
            offset: 1,
            color: 'rgba(1,180,210,0.30)'
          }
        ])
      },
      emphasis: {
        focus: 'series'
      },
      data: [20, 32, 81, 34, 10, 90, 50]
    }
  ]
}
